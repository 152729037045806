// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Navigation bar container */
ul {
    list-style-type: none;
    margin: 0; /* Remove the -433px margin, which might be causing shifting */
    padding: 0;
    display: flex;
    justify-content: center;
    background-color: transparent;
    position: fixed; /* Stays on top of the page when scrolling */
    top: 0;
    left: 0; /* Ensures it starts from the left edge of the screen */
    right: 0; /* Ensures it spans to the right edge of the screen */
    width: 100%; /* Spans the full width of the page */
    height: 80px; /* Taller for a spacious layout */
    z-index: 1000; /* Ensures it's above other elements */
}

/* Navigation items */
li {
    display: inline;
    padding: 25px 40px; /* Padding for spacing */
    font-family: 'Georgia', serif;
    color: #e6ffff; /* Same color as main header */
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    cursor: pointer;
    transition: color 0.3s ease;
}

/* Spacing between items */
li + li {
    margin-left: 40px;
}

/* Links styling */
li a {
    text-decoration: none;
    color: inherit;
}

li a:hover {
    color: #052d91;
}

`, "",{"version":3,"sources":["webpack://./src/Components/NavBar/NavBar.css"],"names":[],"mappings":"AAAA,6BAA6B;AAC7B;IACI,qBAAqB;IACrB,SAAS,EAAE,8DAA8D;IACzE,UAAU;IACV,aAAa;IACb,uBAAuB;IACvB,6BAA6B;IAC7B,eAAe,EAAE,4CAA4C;IAC7D,MAAM;IACN,OAAO,EAAE,uDAAuD;IAChE,QAAQ,EAAE,qDAAqD;IAC/D,WAAW,EAAE,qCAAqC;IAClD,YAAY,EAAE,iCAAiC;IAC/C,aAAa,EAAE,sCAAsC;AACzD;;AAEA,qBAAqB;AACrB;IACI,eAAe;IACf,kBAAkB,EAAE,wBAAwB;IAC5C,6BAA6B;IAC7B,cAAc,EAAE,8BAA8B;IAC9C,iBAAiB;IACjB,gBAAgB;IAChB,sBAAsB;IACtB,yBAAyB;IACzB,eAAe;IACf,2BAA2B;AAC/B;;AAEA,0BAA0B;AAC1B;IACI,iBAAiB;AACrB;;AAEA,kBAAkB;AAClB;IACI,qBAAqB;IACrB,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB","sourcesContent":["/* Navigation bar container */\nul {\n    list-style-type: none;\n    margin: 0; /* Remove the -433px margin, which might be causing shifting */\n    padding: 0;\n    display: flex;\n    justify-content: center;\n    background-color: transparent;\n    position: fixed; /* Stays on top of the page when scrolling */\n    top: 0;\n    left: 0; /* Ensures it starts from the left edge of the screen */\n    right: 0; /* Ensures it spans to the right edge of the screen */\n    width: 100%; /* Spans the full width of the page */\n    height: 80px; /* Taller for a spacious layout */\n    z-index: 1000; /* Ensures it's above other elements */\n}\n\n/* Navigation items */\nli {\n    display: inline;\n    padding: 25px 40px; /* Padding for spacing */\n    font-family: 'Georgia', serif;\n    color: #e6ffff; /* Same color as main header */\n    font-size: 1.2rem;\n    font-weight: 500;\n    letter-spacing: 0.15em;\n    text-transform: uppercase;\n    cursor: pointer;\n    transition: color 0.3s ease;\n}\n\n/* Spacing between items */\nli + li {\n    margin-left: 40px;\n}\n\n/* Links styling */\nli a {\n    text-decoration: none;\n    color: inherit;\n}\n\nli a:hover {\n    color: #052d91;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
